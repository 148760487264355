.header {
	background-color: white; /* Example background color, change as needed */
	text-align: center; /* Center alignment by default */
	padding-top: 1em;
	padding-bottom: 1em;
	line-height: 0;
  }


  /* Tablet viewports and above */
  @media (min-width: 768px) {
	.header {
	  text-align: left; /* Left alignment for tablet and desktop */
	  padding-left: 1em;
	}
  }
  
  .header-logo {
	height: 15px; /* Adjust the size of your logo as needed */
  }
  