.graphic{
    display: grid;
    grid-template-columns: 1fr;
    margin: 1em;
    text-align: center;
}

@media (min-width: 728px){
    .graphic{
        grid-template-columns: 1fr 1fr;
        text-align: left;
    }
}

h1{
    margin: 0;
    padding: 0;
}

.grid-item-dos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2em;
}

