.product-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
	gap: 1em;
  }

  /* Adjustments for 1 column layout */
.product-grid.items-1 {
	grid-template-columns: repeat(1, 1fr); /* 1 column */
  }
  
  /* Responsive layout for mobile */
  @media (max-width: 480px) {
	.product-grid {
	  grid-template-columns: repeat(2, 1fr); /* Default to 2 columns for mobile */
	}
  
	/* Ensure the 1 column layout takes effect when itemsPerRow state is 1 */
	.product-grid.items-1 {
	  grid-template-columns: repeat(1, 1fr);
	}
  }
  
  .product-grid.items-2 {
	grid-template-columns: repeat(2, 1fr); /* 2 columns when toggled */
  }
  
  .product-item {
	width: 100%;
  }
  
  .circle {
	width: 100%;
	padding-top: 100%; /* Makes the div a circle */
	border-radius: 10%;
	overflow: hidden;
	position: relative;
	/* background-color: white; */
  }
  
  .circle img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  .isolated{
	max-width: 100%;
	max-height: 100%;
  }
  .fullbg{
	width: 100%;
  }
  
  /* Responsive layout for tablets */
  @media (max-width: 768px) {
	.product-grid {
	  grid-template-columns: repeat(3, 1fr);
	}
  }
  
  /* Responsive layout for mobile */
  @media (max-width: 480px) {
	.product-grid {
	  grid-template-columns: repeat(2, 1fr);
	}
  }
  