.color-swatches-container {
	display: flex;
	gap: 10px;
	padding: 10px;
	justify-content: center;
  }

  @media (min-width: 728px){
	.color-swatches-container{
		justify-content: left;
	}
  }
  
  .color-swatch {
	width: 30px; /* Adjust size as needed */
	height: 30px;
	border-radius: 50%; /* Makes the div circular */
	border: 1px solid #ccc; /* Optional border */
  }
  