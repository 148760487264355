.dropdown-item{
	font-size: 16px;
}
.download-image{
	background-color: transparent;
	color: white;
	padding: 10px;
	border-radius: 15px;
	font-size: 16px;
	border: 1px solid black;
	color: black;
	cursor: pointer;
	width: 100%;
}

.attribute-tags{
	background-color: black;
	padding: 3px;
	border-radius: 15px;
	font-size: 10px;
	color: white;
	cursor: pointer;
	display: grid;
	width: 80px;
	margin-left: auto; margin-right: auto;
	text-align: center;
}

@media (min-width: 728px){
	.attribute-tags{
		margin-left: unset; margin-right: unset;
	}
}


.dropdown {
	position: relative;
	display: inline-block;
	border-radius: 20px;
	width: 100%;
	font-size: 16px;
  }
  
  .dropdown-button {
	background-color: transparent;
	color: white;
	padding: 10px;
	border-radius: 15px;
	font-size: 16px;
	border: 1px solid black;
	color: black;
	width: 100%;
	cursor: pointer;
	margin-top: .5em;
  }
  
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: white;
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	color: white;
	border-radius: 20px;
  }
  
  .dropdown-content button {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	background: none;
	border: none;
	text-align: left;
	width: 100%;
	cursor: pointer;
  }
  
  .dropdown-content button:hover {
	background-color: #f1f1f1;
	border-radius: 20px;
  }
  
  .dropdown:hover .dropdown-content {
	display: block;
  }
  