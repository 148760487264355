

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns in desktop */
  gap: 20px;
  padding: 20px;
  margin: auto;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
  color: black;
  aspect-ratio: 1 / 1;
  text-align: center;
  position: relative;
}

.card .inner-text{
  position: absolute;
  bottom: 1em;
  font-size: 14px;
  font-weight: bold;
}

.card1, .card2, .card3 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.card1 { background-color: #CBD9DB; }
.card2 { background-color: #EAE3EC; }
.card3 {
  background-color: #FFD0D0;
  grid-column: span 2; /* Spans 2 columns only in desktop */
}
.card4, .card5, .card6, .card7 {
  aspect-ratio: 1 / 1;
  align-items: flex-end;
  padding-bottom: 20px;
}

.card4{
background-color: #C0C8C1;
}
.card5{
background-color: #DDD5D3;
}
.card6{
background-color: #D4DECF;
}
.card7{
background-color: #C3CAC4;
}

.card8 {
  grid-column: 1 / -1; /* Span all columns */
  background-color: #6699ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  aspect-ratio: 16 / 9; /* Desktop aspect ratio */
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
      grid-template-columns: repeat(1, 1fr); /* Ensure single column on mobile */
  }
  .card3 {
      grid-column: span 1; /* Ensure card3 also spans only one column on mobile */
  }
  .card8 {
      aspect-ratio: 9 / 16; /* Mobile aspect ratio */
  }
}
